import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import Client from "../../Client";
import worldSvg from "./world.svg";

export default function Lobby() {
  const [tables, setTables] = useState(null);
  const [createTableName, setCreateTableName] = useState(null);
  const history = useHistory();

  useEffect(function () {
    Client.getTables()
      .then((tables) => setTables(tables))
      .catch((error) => {});
  }, []);

  function handleChangeCreateTableName(e) {
    setCreateTableName(e.target.value);
  }

  function handleSubmitCreateTable() {
    history.push(`/troopers/${createTableName}`);
  }

  return (
    <div className={styles.site}>
      <h1 className={styles.siteTitle}>Board Games Lobby</h1>
      {tables === null ? (
        <div>Loading tables…</div>
      ) : (
        <div className={styles.tables}>
          {tables.map((table) => (
            <div className={styles.table}>
              <img className={styles.tableLogo} src={worldSvg} alt="World" />
              <h2 className={styles.tableName}>{table.name}</h2>
              <div className={styles.countPlayers}>
                Players: {table.players.length}
              </div>
              <Link
                className={styles.tableJoinLink}
                to={`/troopers/${table.name}`}
              >
                Join
              </Link>
            </div>
          ))}
        </div>
      )}
      <form className={styles.createTable} onSubmit={handleSubmitCreateTable}>
        <input
          className={styles.createTableTextInput}
          type="text"
          value={createTableName}
          onChange={handleChangeCreateTableName}
        />
        <button className={styles.createTableButton}>Create table</button>
      </form>
    </div>
  );
}
