const apiUrl = process.env.REACT_APP_SERVER_URL + "/api";

function getTables() {
  return fetch(`${apiUrl}/tables`, {
    accept: "application/json",
  })
    .then(checkStatus)
    .then(parseJSON);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  console.log(error);
  throw error;
}

function parseJSON(response) {
  return response.json();
}

const Client = { getTables };
export default Client;
