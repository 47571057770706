import { curry } from "ramda";

export function insideBoard(state, x, y) {
  const { board } = state;
  return (
    y < board.layout.length && y >= 0 && x < board.layout[y].length && x >= 0
  );
}

export function aBushIsInTheWay(state, x, y) {
  const { board } = state;
  return board.layout[y] && board.layout[y][x] === "B";
}

export function swampEffectOnCoordinate(layoutSwamp, x, y) {
  switch (layoutSwamp[y]?.[x]) {
    case "1":
      return 1;
    case "2":
      return 2;
    default:
      return 0;
  }
}

export function isAdjacentToABush(state, x, y) {
  const { board } = state;
  return (
    (board.layout[y] && board.layout[y]?.[x - 1] === "B") ||
    (board.layout[y] && board.layout[y]?.[x + 1] === "B") ||
    (board.layout[y] && board.layout[y + 1]?.[x + 1] === "B") ||
    (board.layout[y] && board.layout[y + 1]?.[x - 1] === "B") ||
    (board.layout[y] && board.layout[y - 1]?.[x + 1] === "B") ||
    (board.layout[y] && board.layout[y - 1]?.[x - 1] === "B") ||
    (board.layout[y - 1] && board.layout[y - 1]?.[x] === "B") ||
    (board.layout[y + 1] && board.layout[y + 1]?.[x] === "B")
  );
}

export function barbedWireIsInTheWay(state, x, y) {
  const { board } = state;
  return board.layout[y] && board.layout[y][x] === "#";
}

export function aMineIsInTheWay(state, x, y) {
  const { board } = state;
  return board.layout[y] && board.layout[y][x] === "M";
}

export function aPieceIsInTheWay(state, x, y) {
  return !!state.pieces.find((piece) => piece.x === x && piece.y === y);
}

export function flattenBoardLayout(layout) {
  const result = [];
  for (let y = 0; y < layout?.length; y++) {
    for (let x = 0; x < layout[y]?.length; x++) {
      result.push({ x, y, data: layout[y][x] });
    }
  }
  return result;
}

export function isPosInRange(
  posX,
  posY,
  fromPosX,
  fromPosY,
  range,
  isObstacle,
) {
  let inRange = false;
  let obstacleFree = true;
  const diffX = posX - fromPosX;
  const diffY = posY - fromPosY;
  const absDiffX = Math.abs(diffX);
  const absDiffY = Math.abs(diffY);

  if (absDiffX === absDiffY && absDiffX <= range) {
    // diagonally in range
    inRange = true;
    // Check the projectiles path for obstacles
    for (let i = 1; i < absDiffX; i++) {
      const col = fromPosX + i * (diffX / absDiffX);
      const row = fromPosY + i * (diffY / absDiffY);
      if (isObstacle([col, row])) {
        obstacleFree = false;
      }
    }
  }
  if (absDiffY === 0 && absDiffX <= range) {
    // horizontal in range
    inRange = true;
    // Check the projectiles path for obstacles
    for (let i = 1; i < absDiffX; i++) {
      const row = fromPosY;
      const col = fromPosX + i * (diffX / absDiffX);
      if (isObstacle([col, row])) {
        obstacleFree = false;
      }
    }
  }
  if (absDiffX === 0 && absDiffY <= range) {
    // vertical in range
    inRange = true;
    // Check the projectiles path for obstacles
    for (let i = 1; i < absDiffY; i++) {
      const col = fromPosX;
      const row = fromPosY + i * (diffY / absDiffY);
      if (isObstacle([col, row])) {
        obstacleFree = false;
      }
    }
  }
  return [inRange, obstacleFree];
}

export const pieceHasEffect = curry(function (effect, piece) {
  return piece.effects.indexOf(effect) !== -1;
});

export const equalPosition = curry((a, b) => a.x === b.x && a.y === b.y);

export const isControlledBy = curry((playerId, piece) => {
  return piece.controlledBy === playerId;
});

export function getCoordinatesWithCellValue(boardLayout, cellValue) {
  const result = [];
  boardLayout.forEach((row, rowY) => {
    const colX = row.indexOf(cellValue);
    if (colX !== -1) {
      result.push({ x: colX, y: rowY });
    }
  });
  return result;
}

export function randomInt(min, max, prng = Math.random) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(prng() * (max - min + 1)) + min;
}

export function rerollActionCardTier() {
  /*
  Tier 1 (Grün): 65%
  Tier 2 (Blau): 30%
  Tier 3 (Gold): 05%
  */
  const randomNumber = randomInt(0, 99);
  let cardTier;
  if (randomNumber < 65) {
    cardTier = 1;
  } else if (randomNumber < 95) {
    cardTier = 2;
  } else {
    cardTier = 3;
  }
  return cardTier;
}

/**
 * Shuffles the elements of an array in place.
 * @param {Array} array - The array to be shuffled.
 * @returns {void}
 */
export function shuffle(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
}

/**
 * MUTATING, use with immerjs. Draws a card from the given card stack.
 * @param {Object} cardStack - The card stack object (will be mutated).
 * @returns {Object|null} - The drawn card, or null if the stack is empty.
 */
export function drawCardFromStack(cardStack) {
  let card = null;
  if (cardStack.stack.length === 0) {
    cardStack.stack = cardStack.stack2;
    shuffle(cardStack.stack);
    cardStack.stack2 = [];
  }
  if (cardStack.stack.length > 0) {
    card = cardStack.stack.pop();
  }
  return card;
}

/**
 * MUTATING, use with immerjs. Draws a card from the card stacks with a chance of rerolling.
 *
 * @param {Object} cardStacks - The card stacks object.
 * @param {Function} isDrawableCard - Optional. A function that determines if a card is drawable. Defaults to a function that always returns true.
 * @returns {Array} - The drawn card and the stack tier.
 */
export function drawCardWithRerollChance(
  cardStacks,
  isDrawableCard = () => true,
) {
  const stackTier = rerollActionCardTier();
  const cardStack = cardStacks[`actionCardsTier${stackTier}`];
  const isDrawableCardInCardStack = [...cardStack.stack, cardStack.stack2].some(
    isDrawableCard,
  );
  let card = null;
  if (isDrawableCardInCardStack) {
    do {
      if (card !== null) {
        // move not used card back to stack2
        cardStack.stack2.push(card);
      }
      card = drawCardFromStack(cardStack);
    } while (!isDrawableCard(card));
  }
  return [card, stackTier];
}

export function isPieceEffectRemovedAtEndOfEveryTurn(effect) {
  return (
    effect === "mosquito_bite" || effect === "swamp1" || effect === "swamp2"
  );
}

export function isPieceEffectRemovedAtEndOfPlayersTurn(effect) {
  return effect === "dengue_fever" || effect === "stun-grenade";
}

export function isPieceEffectRemovedAtStartOfPlayersTurn(effect) {
  return effect === "camouflage_vest";
}

// Target must be in range
export const isBulletObstacle = curry(
  (boardLayout, pieces, specialEffects, shooterPiece, bordCell) => {
    specialEffects = specialEffects || [];
    const [col, row] = bordCell;
    if (specialEffects.includes("piercing")) {
      return false;
    }
    if (shooterPiece.type === "rocket-lady") {
      // Rocket lady can shoot over everything
      return false;
    }
    const boardField = boardLayout[row][col];
    if (boardField === "B") {
      return true;
    }
    const pieceInTheWay = pieces.find(
      (piece) => piece.x === col && piece.y === row,
    );
    if (
      pieceInTheWay &&
      pieceInTheWay.controlledBy !== shooterPiece.controlledBy
    ) {
      return true;
    }
    return false;
  },
);
