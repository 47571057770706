import React from "react";

import { concatClassNames as cn } from "@sys42/utils";

import styles from "./styles.module.css";

export function ItemPiece(props) {
  const { type, pos } = props;
  return (
    <div
      title={`${type}`}
      className={cn(styles.itemPiece, styles[`itemPiece_${type}`])}
      style={{ left: pos.x + "em", top: pos.y + "em" }}
    />
  );
}
