import { applyPatches } from "immer";
import { useEffect, useRef, useState, useCallback } from "react";
import socketIOClient from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_SERVER_URL;
// https://medium.com/@kozak.jakub55/build-a-real-time-chat-app-with-react-hooks-and-socket-io-4859c9afecb0
const useTroopersServer = (tableName) => {
  const [gameState, setGameState] = useState(null);
  const [lastError, setLastError] = useState(null);
  const [playerIndex, setPlayerIndex] = useState(null);
  const socketRef = useRef();

  useEffect(() => {
    // Creates a WebSocket connection
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { tableName },
    });

    // Listens for incoming state messages
    socketRef.current.on("state", (data) => {
      // console.log("State received.", data);
      setGameState(data);
    });

    // Listens for incoming state-patch messages
    socketRef.current.on("state-patch", (data) => {
      // console.log("State patch received.", data);
      setGameState((gameState) => applyPatches(gameState, data));
    });

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socketRef.current.disconnect();
    };
  }, [tableName]);

  // Sends a message to the server that
  // forwards it to all users in the same room
  const sendAction = useCallback((action) => {
    socketRef.current.emit("action", action, (response) => {
      if (response.error) {
        setLastError(response.error);
      }
    });
  }, []);

  const sendReady = (username) => {
    socketRef.current.emit("ready", { username }, (response) => {
      if (response.error) {
        setLastError(response.error);
      } else if (typeof response.data?.playerIndex !== "undefined") {
        setPlayerIndex(response.data.playerIndex);
      }
    });
  };

  return { gameState, sendAction, lastError, sendReady, playerIndex };
};

export { useTroopersServer };
