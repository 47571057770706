import styles from "./styles.module.css";
import React from "react";

export function ActionCard(props) {
  const isPassiveCard =
    props.card?.type === "wormhole_helmet" ||
    props.card?.type === "ground_air_defense";

  const isShootCard =
    [
      "piercing",
      "dumdum",
      "sniper_bullet",
      "laser_pointer",
      "super_special_scope",
      "scope",
    ].indexOf(props.card?.type) !== -1;
  const isStabCard = ["katana"].indexOf(props.card?.type) !== -1;

  const isKickCard = ["roundhouse_kick"].indexOf(props.card?.type) !== -1;

  return (
    <div className={`${styles.card} ${props.className || ""}`}>
      <div className={styles.cardTier}>Tier: {props.card.tier}</div>
      <div className={styles.cardTitle}>{props.card.title}</div>
      <div className={styles.cardDescription}>{props.card.description}</div>
      <div className={styles.effectDescription}>
        {props.card.effectDescription}
      </div>
      {props.onClickPlayCard &&
        !isPassiveCard &&
        !isShootCard &&
        !isKickCard && (
          <button
            className={styles.buttonPlayCard}
            onClick={props.onClickPlayCard}
          >
            Play card
          </button>
        )}
      {isShootCard && (
        <button
          className={styles.buttonPlayCard}
          onClick={props.onClickPlayCard}
        >
          Shoot
        </button>
      )}
      {isStabCard && (
        <button
          className={styles.buttonPlayCard}
          onClick={props.onClickPlayCard}
        >
          {props.card?.type === "katana" ? "Slice" : "Stab"}
        </button>
      )}
      {isKickCard && (
        <button
          className={styles.buttonPlayCard}
          onClick={props.onClickPlayCard}
        >
          Kick
        </button>
      )}
    </div>
  );
}
