import React, { useEffect, useMemo, useState } from "react";
import jsAstar from "javascript-astar";
import { concatClassNames as cn } from "@sys42/utils";
import styles from "./styles.module.css";
import {
  INTERFACE_MODE_SELECT_COORDINATES,
  INTERFACE_MODE_THROW_GRENADE,
  INTERFACE_MODE_THROW_SUPER_GRENADE,
  INTERFACE_MODE_THROW_STUN_GRENADE,
  INTERFACE_MODE_ACTION_MENU,
} from "../../constants";
import { getReachableCells, whichBush, buildJsAstarGraph } from "../../helpers";
import {
  isPosInRange,
  flattenBoardLayout,
  swampEffectOnCoordinate,
} from "../../wargame/helpers";

import { ItemPiece } from "../ItemPiece";
import { PlayerPiece } from "../PlayerPiece";
import { usePiecesInShootRange } from "../../hooks/usePiecesInShootRange";

function Board(props) {
  const {
    gameState,
    highlightedPiece: highlightedPieceIndex,
    piecesInShootRangeForHighlightedPiece,
    actionMenuTarget,
    interfaceMode,
    onClickCell,
    onClickPiece,
    onClickActionMenuShoot,
    onClickActionMenuStab,
    onClickActionMenuCancel,
  } = props;
  const { board, players, pieces, items, activePlayer, activeShooting } =
    gameState;
  const activeShooter = activeShooting?.shooter;
  const activeTarget = activeShooting?.target;
  const [hoveredReachableCell, setHoveredReachableCell] = useState(null);

  const [hoverCoordinates, setHoverCoordinates] = useState(null);

  const mouseCatcherActive =
    interfaceMode === INTERFACE_MODE_SELECT_COORDINATES ||
    interfaceMode === INTERFACE_MODE_THROW_GRENADE ||
    interfaceMode === INTERFACE_MODE_THROW_STUN_GRENADE ||
    interfaceMode === INTERFACE_MODE_THROW_SUPER_GRENADE;

  function handleClickMouseCatcher(coordinates) {
    onClickCell({ coordinates }, null);
  }

  function handleChangeHoveredReachableCell(coordinates) {
    setHoveredReachableCell(coordinates);
  }

  // This is needed to reset the hovered reachable cell when the highlighted piece changes
  useEffect(() => {
    setHoveredReachableCell(null);
  }, [highlightedPieceIndex]);

  // GameBoard Layers
  // 1. Mouse catcher
  // 2. Starting positions
  // 3. Pieces (figures, items)
  // 4. Board (ground, bushes, ...)

  const piecesInShootRangeForHoveredReachableCell = usePiecesInShootRange(
    highlightedPieceIndex,
    gameState,
    hoveredReachableCell,
  );

  return (
    <div className={styles.board}>
      {/*       <StartingPositions
        active={gameState.turn === -1}
        startingPositions={board.startingPositions}
        onClickStartingPosition={onClickStartingPosition}
      /> */}
      <ActionCardDropZones board={board} />
      <Items items={items} />
      <Mines board={board} />
      <Bushes board={board} />
      <NoWalkZones board={board} />
      <Pieces
        pieces={pieces}
        hoveredReachableCell={hoveredReachableCell}
        piecesInShootRange={
          hoveredReachableCell !== null
            ? piecesInShootRangeForHoveredReachableCell
            : piecesInShootRangeForHighlightedPiece
        }
        players={players}
        highlightedPieceIndex={highlightedPieceIndex}
        activeShooter={activeShooter}
        activeTarget={activeTarget}
        activePlayer={activePlayer}
        onClick={onClickPiece}
      />
      <ReachableCells
        gameState={gameState}
        hoveredReachableCell={hoveredReachableCell}
        onChangeHoveredReachableCell={handleChangeHoveredReachableCell}
        highlightedPieceIndex={highlightedPieceIndex}
        onClickReachableCell={onClickCell}
      />
      <RawLayoutInfoLayers board={board} isEnabled={false} />
      <ThrowGrenadeInterface
        interfaceMode={interfaceMode}
        highlightedPieceIndex={highlightedPieceIndex}
        board={board}
        pieces={pieces}
        hoverCoordinates={hoverCoordinates}
      />
      <MouseCatcher
        active={mouseCatcherActive}
        board={board}
        onChangeHoverCoordinates={setHoverCoordinates}
        onClick={handleClickMouseCatcher}
      />
      <OnBoardInterface
        interfaceMode={interfaceMode}
        actionMenuTarget={actionMenuTarget}
        gameState={gameState}
        onClickActionMenuShoot={onClickActionMenuShoot}
        onClickActionMenuStab={onClickActionMenuStab}
        onClickActionMenuCancel={onClickActionMenuCancel}
      />
    </div>
  );
}

function OnBoardInterface(props) {
  const {
    interfaceMode,
    actionMenuTarget,
    gameState,
    onClickActionMenuShoot,
    onClickActionMenuStab,
    onClickActionMenuCancel,
  } = props;

  const actionMenuCoordinates = { x: 0, y: 0 };
  if (actionMenuTarget !== null) {
    const piece = gameState.pieces[actionMenuTarget];
    actionMenuCoordinates.x = piece.x;
    actionMenuCoordinates.y = piece.y;
  }

  return (
    <div className={styles.layer} data-layer-name={"OnBoardInterface"}>
      {interfaceMode === INTERFACE_MODE_ACTION_MENU && (
        <ActionMenu
          coordinates={actionMenuCoordinates}
          onClickShoot={onClickActionMenuShoot}
          onClickStab={onClickActionMenuStab}
          onClickCancel={onClickActionMenuCancel}
        />
      )}
    </div>
  );
}

function ActionMenu(props) {
  const { onClickShoot, onClickStab, onClickCancel, coordinates } = props;
  const style = {
    left: coordinates.x + 0.5 + "em",
    top: coordinates.y + 0.5 + "em",
  };
  return (
    <div className={styles.actionMenu} style={style}>
      <button onClick={() => onClickShoot(coordinates)}>Shoot</button>
      <button onClick={() => onClickStab(coordinates)}>Stab</button>
      <button onClick={onClickCancel}>Cancel</button>
    </div>
  );
}

function ThrowGrenadeInterface({
  hoverCoordinates,
  interfaceMode,
  highlightedPieceIndex,
  board,
  pieces,
}) {
  return (
    <div className={cn(styles.layer)} data-layer-name={"ThrowGrenadeInterface"}>
      {board.layout.map((row, y) =>
        row.map((cell, x) => {
          if (
            interfaceMode === INTERFACE_MODE_THROW_GRENADE ||
            interfaceMode === INTERFACE_MODE_THROW_STUN_GRENADE ||
            interfaceMode === INTERFACE_MODE_THROW_SUPER_GRENADE
          ) {
            let innerDamage, outerDamage;
            if (interfaceMode === INTERFACE_MODE_THROW_SUPER_GRENADE) {
              innerDamage = 3;
              outerDamage = 2;
            } else if (interfaceMode === INTERFACE_MODE_THROW_STUN_GRENADE) {
              innerDamage = "";
              outerDamage = "";
            } else {
              innerDamage = 2;
              outerDamage = 1;
            }
            const highlightedPiece = pieces[highlightedPieceIndex];
            if (
              !highlightedPiece ||
              !isPosInRange(
                x,
                y,
                highlightedPiece.x,
                highlightedPiece.y,
                4,
                () => false,
              )
            ) {
              return false;
            }
            if (hoverCoordinates?.x === x && hoverCoordinates?.y === y) {
              return (
                <div
                  key={x + "-" + y}
                  className={cn(styles.overlayCell)}
                  style={{ left: x + "em", top: y + "em" }}
                >
                  {innerDamage}
                </div>
              );
            } else if (
              Math.abs(x - hoverCoordinates?.x) <= 1 &&
              Math.abs(hoverCoordinates?.y - y) <= 1
            ) {
              return (
                <div
                  key={x + "-" + y}
                  className={cn(styles.overlayCell)}
                  style={{ left: x + "em", top: y + "em" }}
                >
                  {outerDamage}
                </div>
              );
            }
          }
          return false;
        }),
      )}
    </div>
  );
}

function ReachableCells({
  gameState,
  highlightedPieceIndex,
  hoveredReachableCell,
  onClickReachableCell,
  onChangeHoveredReachableCell,
}) {
  const { board } = gameState;

  const withJetpack = false;
  const reachableCells = getReachableCells(
    gameState,
    highlightedPieceIndex,
    withJetpack,
  );

  const path = useMemo(() => {
    /* Set path for highlighted piece */
    const highlightedPiece = gameState?.pieces[highlightedPieceIndex];
    let path = null;
    if (highlightedPiece && hoveredReachableCell !== null) {
      const graph = buildJsAstarGraph(
        board.layout,
        board.layoutSwamp,
        reachableCells,
      );
      const start = graph.grid[highlightedPiece.y]?.[highlightedPiece.x];
      const end = graph.grid[hoveredReachableCell.y]?.[hoveredReachableCell.x];
      // If highlighted piece is dead and coordinates are -1 -1 this is needed
      if (start && end) {
        path = jsAstar.astar.search(graph, start, end);
      }
    }
    return path;
  }, [
    hoveredReachableCell,
    highlightedPieceIndex,
    board.layout,
    board.layoutSwamp,
    reachableCells,
    gameState,
  ]); // Todo: hmm

  function handleClickReachableCell(coordinates) {
    onClickReachableCell({ coordinates, path });
  }

  return (
    <>
      <div className={cn(styles.layer)} data-layer-name={"ReachableCells"}>
        {flattenBoardLayout(board.layout)
          .filter((item) => reachableCells?.[item.x]?.[item.y])
          .map(({ x, y }, index) => (
            <div
              key={index}
              className={styles.cellReachable}
              onMouseOver={() => onChangeHoveredReachableCell({ x, y })}
              onMouseOut={() => onChangeHoveredReachableCell(null)}
              onClick={() => handleClickReachableCell({ x, y })}
              style={{ left: x + "em", top: y + "em" }}
            />
          ))}
      </div>
      <div className={cn(styles.layer)} data-layer-name={"ReachableCellsPath"}>
        {path &&
          path.map((pathItem) => (
            <div
              key={pathItem.x + "-" + pathItem.y}
              className={styles.overlayCellPath}
              style={{ left: pathItem.y + "em", top: pathItem.x + "em" }}
            />
          ))}
      </div>
    </>
  );
}

function Pieces({
  pieces,
  players,
  highlightedPieceIndex,
  hoveredReachableCell,
  activeShooter,
  activeTarget,
  activePlayer,
  onClick,
  piecesInShootRange,
}) {
  return (
    <div className={styles.layer} data-layer-name={"Pieces"}>
      {pieces.map((piece, pieceIndex) => {
        const isHighlighted = pieceIndex === highlightedPieceIndex;

        let displayedCoordinates;
        let isHoverState;
        if (isHighlighted && hoveredReachableCell !== null) {
          displayedCoordinates = hoveredReachableCell;
          isHoverState = true;
        } else {
          displayedCoordinates = { x: piece.x, y: piece.y };
          isHoverState = false;
        }

        return (
          <PlayerPiece
            key={pieceIndex}
            type={piece.type}
            isHoverState={isHoverState}
            pos={displayedCoordinates}
            playerIndex={piece.controlledBy}
            playerName={players[piece.controlledBy]?.name}
            isActiveShooter={activeShooter === pieceIndex}
            isActiveTarget={activeTarget === pieceIndex}
            isHighlighted={isHighlighted}
            isControlledByActivePlayer={piece.controlledBy === activePlayer}
            isInRange={piecesInShootRange.includes(piece)}
            effects={piece.effects}
            stackedItems={piece.stackedItems}
            movementState={piece.movementState}
            health={piece.health}
            maxHealth={piece.stats.health}
            countActionPoints={piece.ap}
            onClick={() => onClick(pieceIndex)}
          />
        );
      })}
    </div>
  );
}

function Items({ items }) {
  return (
    <div className={styles.layer} data-layer-name={"Items"}>
      {items.map((item, itemIndex) => (
        <ItemPiece
          key={itemIndex}
          type={item.type}
          pos={{ x: item.x, y: item.y }}
        />
      ))}
    </div>
  );
}

function Bushes({ board }) {
  return (
    <div className={styles.layer} data-layer-name={"Bushes"}>
      {flattenBoardLayout(board.layout).map(({ x, y }, index) => {
        const whichBushResult = whichBush(x, y, board.layout);
        if (whichBushResult) {
          return (
            <div
              key={index}
              title={`Bush`}
              className={cn(styles.bush)}
              style={{ left: x + "em", top: y + "em" }}
            />
          );
        }
        return false;
      })}
    </div>
  );
}

function Mines({ board }) {
  return (
    <div className={styles.layer} data-layer-name={"Mines"}>
      {flattenBoardLayout(board.layout)
        .filter((item) => item.data === "M")
        .map(({ x, y }) => (
          <div
            key={x + "-" + y}
            className={cn(styles.cellStuff, styles.mine)}
            style={{ left: x + "em", top: y + "em" }}
          />
        ))}
    </div>
  );
}

function NoWalkZones({ board }) {
  return (
    <div className={styles.layer} data-layer-name={"NoWalkZones"}>
      {flattenBoardLayout(board.layout)
        .filter((item) => item.data === "#")
        .map(({ x, y }) => {
          const swampEffect = swampEffectOnCoordinate(board.layoutSwamp, x, y);
          return (
            <div
              key={x + "-" + y}
              className={cn(
                styles.cellStuff,
                styles.noWalkZone,
                swampEffect === 2 && styles.noWalkZone_swamp2,
              )}
              style={{ left: x + "em", top: y + "em" }}
            />
          );
        })}
    </div>
  );
}

function ActionCardDropZones({ board }) {
  return (
    <div className={styles.layer} data-layer-name={"ActionCardDropZones"}>
      {flattenBoardLayout(board.layout)
        .filter((item) => Number(item.data) > 0)
        .map(({ x, y, data }) => (
          <div
            key={x + "-" + y}
            className={cn(styles.cellStuff, styles.actionCardDropZone)}
            style={{ left: x + "em", top: y + "em" }}
          >
            <div>{data}</div>
          </div>
        ))}
    </div>
  );
}

function RawLayoutInfoLayers({ board, isEnabled }) {
  if (!isEnabled) {
    return null;
  }
  return (
    <>
      <RawInfoLayer layout={board.layout} color={"red"} />
      <RawInfoLayer layout={board.layoutSwamp} color={"blue"} />
    </>
  );
}

function RawInfoLayer({ layout, color }) {
  return (
    <div className={styles.layer} data-layer-name={"RawInfoLayer"}>
      {flattenBoardLayout(layout).map(({ x, y, data }, index) => (
        <div
          key={index}
          className={styles.cell}
          style={{ left: x + "em", top: y + "em", color }}
        >
          {data}
        </div>
      ))}
    </div>
  );
}

function MouseCatcher({ active, board, onChangeHoverCoordinates, onClick }) {
  if (!active) {
    return null;
  }

  function handleMouseOverCell(coordinates) {
    onChangeHoverCoordinates(coordinates);
  }

  function handleMouseOutCell() {
    onChangeHoverCoordinates(null);
  }

  return (
    <div
      className={cn(styles.layer, styles.layer_mouseCatcher)}
      data-layer-name={"MouseCatcher"}
    >
      {flattenBoardLayout(board.layout).map(({ x, y }) => (
        <div
          key={x + "-" + y}
          className={styles.cell}
          style={{ left: x + "em", top: y + "em" }}
          onClick={() => onClick({ x, y })}
          onMouseOver={() => handleMouseOverCell({ x, y })}
          onMouseOut={handleMouseOutCell}
        />
      ))}
    </div>
  );
}

export default Board;
