import { useRef, useEffect } from "react";
import jsAstar from "javascript-astar";
import {
  aBushIsInTheWay,
  aPieceIsInTheWay,
  barbedWireIsInTheWay,
  insideBoard,
} from "./wargame/helpers";

export function concatClassNames(...args) {
  return args.filter((item) => !!item).join(" ");
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function canMove(gameState, x, y, withJetpack = false) {
  if (withJetpack === true) {
    return insideBoard(gameState, x, y);
  } else {
    return (
      insideBoard(gameState, x, y) &&
      !aBushIsInTheWay(gameState, x, y) &&
      !barbedWireIsInTheWay(gameState, x, y) &&
      !aPieceIsInTheWay(gameState, x, y)
    );
  }
}

export function getReachableCells(
  gameState,
  highlightedPieceIndex,
  withJetpack = false,
) {
  function reachableCells(x, y, mp, withJetpack, result = []) {
    if (mp <= 0 /* || mp <= swampEffectOnCoordinate(layoutSwamp, x, y) */) {
      return result;
    }
    if (
      canMove(gameState, x + 1, y, withJetpack) &&
      (result[x + 1]?.[y] === undefined || result[x + 1]?.[y] < mp)
    ) {
      if (result[x + 1] === undefined) {
        result[x + 1] = [];
      }
      result[x + 1][y] = mp;
      result = reachableCells(x + 1, y, mp - 1, withJetpack, result);
    }
    if (
      canMove(gameState, x - 1, y, withJetpack) &&
      (result[x - 1]?.[y] === undefined || result[x - 1]?.[y] < mp)
    ) {
      if (result[x - 1] === undefined) {
        result[x - 1] = [];
      }
      result[x - 1][y] = mp;
      result = reachableCells(x - 1, y, mp - 1, withJetpack, result);
    }
    if (
      canMove(gameState, x, y + 1, withJetpack) &&
      (result[x]?.[y + 1] === undefined || result[x]?.[y + 1] < mp)
    ) {
      if (result[x] === undefined) {
        result[x] = [];
      }
      result[x][y + 1] = mp;
      result = reachableCells(x, y + 1, mp - 1, withJetpack, result);
    }
    if (
      canMove(gameState, x, y - 1) &&
      (result[x]?.[y - 1] === undefined || result[x]?.[y - 1] < mp)
    ) {
      if (result[x] === undefined) {
        result[x] = [];
      }
      result[x][y - 1] = mp;
      result = reachableCells(x, y - 1, mp - 1, withJetpack, result);
    }
    return result;
  }

  const piece = gameState.pieces[highlightedPieceIndex];

  if (piece) {
    return reachableCells(piece.x, piece.y, piece.mp, withJetpack);
  }
  return [];
}

export function whichBush(x, y, layout) {
  const cell = layout[y][x];
  if (cell === "B") {
    const cellNW = layout[y - 1]?.[x - 1];
    const cellN = layout[y - 1]?.[x];
    const cellNE = layout[y - 1]?.[x + 1];
    const cellE = layout[y]?.[x + 1];
    const cellSE = layout[y + 1]?.[x + 1];
    const cellS = layout[y + 1]?.[x];
    const cellSW = layout[y + 1]?.[x - 1];
    const cellW = layout[y]?.[x - 1];
    if (
      cellNW !== "B" &&
      cellN !== "B" &&
      cellNE !== "B" &&
      cellE !== "B" &&
      cellSE !== "B" &&
      cellS !== "B" &&
      cellSW !== "B" &&
      cellW !== "B"
    ) {
      return "bush-1";
    } else if (
      cellNW !== "B" &&
      cellN !== "B" &&
      cellNE !== "B" &&
      cellE !== "B" &&
      cellSE !== "B" &&
      cellS === "B" &&
      cellSW === "B" &&
      cellW !== "B"
    ) {
      return "bush-l1";
    } else if (
      cellNW !== "B" &&
      cellN !== "B" &&
      cellNE !== "B" &&
      cellE === "B" &&
      cellSE !== "B" &&
      cellS === "B" &&
      cellSW !== "B" &&
      cellW !== "B"
    ) {
      return "bush-l2";
    } else if (
      cellNW !== "B" &&
      cellN !== "B" &&
      cellNE !== "B" &&
      cellE !== "B" &&
      cellSE === "B" &&
      cellS !== "B" &&
      cellSW !== "B" &&
      cellW !== "B"
    ) {
      return "bush-diagonal";
    } else if (
      cellNW !== "B" &&
      cellN !== "B" &&
      cellNE === "B" &&
      cellE !== "B" &&
      cellSE !== "B" &&
      cellS !== "B" &&
      cellSW !== "B" &&
      cellW !== "B"
    ) {
      return "bush-diagonal2";
    } else if (
      cellNW !== "B" &&
      cellN !== "B" &&
      cellNE !== "B" &&
      cellE === "B" &&
      cellSE !== "B" &&
      cellS !== "B" &&
      cellSW !== "B" &&
      cellW !== "B"
    ) {
      return "bush-horizontal";
    } else {
      return "bush-1";
    }
  }
  return false;
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function buildJsAstarGraph(
  boardLayout,
  boardLayoutSwamp,
  reachableCells,
) {
  const grid = boardLayout.map((row, y) =>
    row.map((cell, x) => {
      return typeof reachableCells[x]?.[y] === "undefined" ? 0 : 1;
      //const swampEffect = swampEffectOnCoordinate(boardLayoutSwamp, x, y);
      //return typeof reachableCells[x]?.[y] === 'undefined' ? 0 : swampEffect + 1;
    }),
  );
  return new jsAstar.Graph(grid);
}
