import { useEffect } from "react";
import { usePrevious } from "../../helpers";
import useSound from "use-sound";

import sfxAc from "./sfx/AC.mp3";
import sfxAcRanger from "./sfx/AC_RANGER.mp3";
import sfxAcRocketLady from "./sfx/AC_ROCKET.mp3";
import sfxAcSniper from "./sfx/AC_SNIPER.mp3";
import sfxAcAirstrike from "./sfx/AC_AIRSTRIKE.mp3";
import sfxGrenadeBlueRanger from "./sfx/GRENADE_BLUE_RANGER.mp3";
import sfxGrenadeBlueRocketLady from "./sfx/GRENADE_BLUE_ROCKET.mp3";
import sfxGrenadeBlueSniper from "./sfx/GRENADE_BLUE_SNIPER.mp3";
import sfxGrenadeRedRanger from "./sfx/GRENADE_RED_RANGER.mp3";
import sfxGrenadeRedRocketLady from "./sfx/GRENADE_RED_ROCKET.mp3";
import sfxGrenadeRedSniper from "./sfx/GRENADE_RED_SNIPER.mp3";
import sfxGrenadeGreenRanger from "./sfx/GRENADE_GREEN_RANGER.mp3";
import sfxGrenadeGreenRocketLady from "./sfx/GRENADE_GREEN_ROCKET.mp3";
import sfxGrenadeGreenSniper from "./sfx/GRENADE_GREEN_SNIPER.mp3";
import sfxHealth from "./sfx/HEALTH.mp3";
import sfxLandmineExplosion from "./sfx/LANDMINE_EXPLOSION.mp3";
import sfxLandmineFail from "./sfx/LANDMINE_FAIL.mp3";
import sfxRangerHitsRanger from "./sfx/RANGER_HITS_RANGER.mp3";
import sfxRangerHitsRocketLady from "./sfx/RANGER_HITS_ROCKET_LADY.mp3";
import sfxRangerHitsSniper from "./sfx/RANGER_HITS_SNIPER.mp3";
import sfxRocketHitsRanger from "./sfx/ROCKET_HITS_RANGER.mp3";
import sfxRocketHitsRocketLady from "./sfx/ROCKET_HITS_ROCKET_LADY.mp3";
import sfxRocketHitsSniper from "./sfx/ROCKET_HITS_SNIPER.mp3";
import sfxSniperHitRanger from "./sfx/SNIPER_HIT_RANGER.mp3";
import sfxSniperHitRocketLady from "./sfx/SNIPER_HIT_ROCKET_LADY.mp3";
import sfxSniperHitSniper from "./sfx/SNIPER_HIT_SNIPER.mp3";
import sfxReady from "./sfx/READY.mp3";
import sfxStabRanger from "./sfx/STAB_RANGER.mp3";
import sfxStabRocketLady from "./sfx/STAB_ROCKET_LADY.mp3";
import sfxStabSniper from "./sfx/STAB_SNIPER.mp3";
import sfxThrowGrenade from "./sfx/THROW_GRENADE.mp3";
import sfxWin from "./sfx/WIN.mp3";
import sfxLoser from "./sfx/LOSER.mp3";
import sfxYourTurn from "./sfx/YOUR_TURN.mp3";
import sfxEraserGun from "./sfx/ERASER_GUN.mp3";
import sfxMiss from "./sfx/MISS.mp3";
import sfxMissRanger from "./sfx/MISS_RANGER.mp3";
import sfxMissRocketLady from "./sfx/MISS_ROCKET.mp3";
import sfxMissSniper from "./sfx/MISS_SNIPER.mp3";
import sfxRoundhouse from "./sfx/ROUNDHOUSEKICK.mp3";
import sfxMoving from "./sfx/MOVING_1.mp3";
import sfxGameStart from "./sfx/GAME_START.mp3";
import sfxDefendSniper from "./sfx/DEFEND_SNIPER.mp3";
import sfxDefendRanger from "./sfx/DEFEND_RANGER.mp3";
import sfxDefendRocketLady from "./sfx/DEFEND_ROCKET_LADY.mp3";
import sfxSwampSinking from "./sfx/SWAMP_SINKING.mp3";
import sfxMedkitRanger from "./sfx/MEDKIT_RANGER.mp3";
import sfxMedkitRocketLady from "./sfx/MEDKIT_ROCKET.mp3";
import sfxMedkitSniper from "./sfx/MEDKIT_SNIPER.mp3";
import sfxPotionRanger from "./sfx/POTION_RANGER.mp3";
import sfxPotionRocketLady from "./sfx/POTION_ROCKET.mp3";
import sfxPotionSniper from "./sfx/POTION_SNIPER.mp3";
import sfxSelectRanger from "./sfx/SELECT_RANGER.mp3";
import sfxSelectRocketLady from "./sfx/SELECT_ROCKET_LADY.mp3";
import sfxSelectSniper from "./sfx/SELECT_SNIPER.mp3";

import sfxUseMed from "./sfx/USE_MED.mp3";

/*
import sfxShotRanger from "./sfx/SHOT_RANGER.mp3";
import sfxShotRocketLady from "./sfx/SHOT_ROCKET_LADY.mp3";
import sfxShotSniper from "./sfx/SHOT_SNIPER.mp3";
*/
import { pieceHasEffect } from "../../wargame/helpers";

const useMySound = (soundUrl) => useSound(soundUrl, { volume: 0.5 });

export function useToopersSoundEffects(
  gameState,
  activePieceIndex,
  playerIndex,
  isSpectatorMode,
) {
  const { pieces, items, log, activePlayer } = gameState || {};
  const prevGameState = usePrevious(gameState);
  const prevPieces = usePrevious(pieces);
  const prevItems = usePrevious(items);
  const prevLog = usePrevious(log);
  const prevActivePieceIndex = usePrevious(activePieceIndex);
  const activePiece = pieces?.[activePieceIndex];

  const [playSfxStabRanger] = useMySound(sfxStabRanger);
  const [playSfxStabRocketLady] = useMySound(sfxStabRocketLady);
  const [playSfxStabSniper] = useMySound(sfxStabSniper);
  const [playSfxAc] = useMySound(sfxAc);
  const [playSfxAcRanger] = useMySound(sfxAcRanger);
  const [playSfxAcRocketLady] = useMySound(sfxAcRocketLady);
  const [playSfxAcSniper] = useMySound(sfxAcSniper);
  const [playSfxAcAirstrike] = useMySound(sfxAcAirstrike);
  const [playSfxGrenadeBlueRanger] = useMySound(sfxGrenadeBlueRanger);
  const [playSfxGrenadeBlueRocketLady] = useMySound(sfxGrenadeBlueRocketLady);
  const [playSfxGrenadeBlueSniper] = useMySound(sfxGrenadeBlueSniper);
  const [playSfxGrenadeRedRanger] = useMySound(sfxGrenadeRedRanger);
  const [playSfxGrenadeRedRocketLady] = useMySound(sfxGrenadeRedRocketLady);
  const [playSfxGrenadeRedSniper] = useMySound(sfxGrenadeRedSniper);
  const [playSfxGrenadeGreenRanger] = useMySound(sfxGrenadeGreenRanger);
  const [playSfxGrenadeGreenRocketLady] = useMySound(sfxGrenadeGreenRocketLady);
  const [playSfxGrenadeGreenSniper] = useMySound(sfxGrenadeGreenSniper);
  const [playSfxThrowGrenade] = useMySound(sfxThrowGrenade);
  const [playSfxHealth] = useMySound(sfxHealth);
  const [playSfxReady] = useMySound(sfxReady);
  const [playSfxUseMed] = useMySound(sfxUseMed);

  const [playSfxLandmineExplosion] = useMySound(sfxLandmineExplosion);
  const [playSfxLandmineFail] = useMySound(sfxLandmineFail);
  const [playSfxRangerHitsRanger] = useMySound(sfxRangerHitsRanger);
  const [playSfxRangerHitsRocketLady] = useMySound(sfxRangerHitsRocketLady);
  const [playSfxRangerHitsSniper] = useMySound(sfxRangerHitsSniper);
  const [playSfxRocketHitsRanger] = useMySound(sfxRocketHitsRanger);
  const [playSfxRocketHitsRocketLady] = useMySound(sfxRocketHitsRocketLady);
  const [playSfxRocketHitsSniper] = useMySound(sfxRocketHitsSniper);
  const [playSfxSniperHitRanger] = useMySound(sfxSniperHitRanger);
  const [playSfxSniperHitRocketLady] = useMySound(sfxSniperHitRocketLady);
  const [playSfxSniperHitSniper] = useMySound(sfxSniperHitSniper);

  const [playSfxWin] = useMySound(sfxWin);
  const [playSfxLoser] = useMySound(sfxLoser);
  const [playSfxYourTurn] = useMySound(sfxYourTurn);
  const [playSfxEraserGun] = useMySound(sfxEraserGun);
  const [playSfxMiss] = useMySound(sfxMiss);
  const [playSfxMissRanger] = useMySound(sfxMissRanger);
  const [playSfxMissRocketLady] = useMySound(sfxMissRocketLady);
  const [playSfxMissSniper] = useMySound(sfxMissSniper);
  const [playSfxRoundhouse] = useMySound(sfxRoundhouse);
  const [playSfxMoving] = useMySound(sfxMoving);
  const [playSfxGameStart] = useMySound(sfxGameStart);
  const [playSfxDefendSniper] = useMySound(sfxDefendSniper);
  const [playSfxDefendRanger] = useMySound(sfxDefendRanger);
  const [playSfxDefendRocketLady] = useMySound(sfxDefendRocketLady);
  const [playSfxSwampSinking] = useMySound(sfxSwampSinking);
  const [playSfxMedkitRanger] = useMySound(sfxMedkitRanger);
  const [playSfxMedkitRocketLady] = useMySound(sfxMedkitRocketLady);
  const [playSfxMedkitSniper] = useMySound(sfxMedkitSniper);
  const [playSfxPotionRanger] = useMySound(sfxPotionRanger);
  const [playSfxPotionRocketLady] = useMySound(sfxPotionRocketLady);
  const [playSfxPotionSniper] = useMySound(sfxPotionSniper);

  const [playSfxSelectRanger] = useMySound(sfxSelectRanger);
  const [playSfxSelectRocketLady] = useMySound(sfxSelectRocketLady);
  const [playSfxSelectSniper] = useMySound(sfxSelectSniper);

  useEffect(() => {
    if (
      activePieceIndex !== null &&
      activePieceIndex !== prevActivePieceIndex &&
      playerIndex === activePlayer &&
      activePiece.controlledBy === playerIndex
    ) {
      if (activePiece.type === "ranger") {
        playSfxSelectRanger();
      } else if (activePiece.type === "rocket-lady") {
        playSfxSelectRocketLady();
      } else if (activePiece.type === "sniper") {
        playSfxSelectSniper();
      }
    }
  }, [
    playerIndex,
    activePlayer,
    activePiece,
    activePieceIndex,
    prevActivePieceIndex,
    playSfxSelectRanger,
    playSfxSelectRocketLady,
    playSfxSelectSniper,
  ]);

  // Any piece got swamp
  useEffect(() => {
    // call playSfxSwampSinking if 'swamp1' or 'swamp2' appear in the
    // piece.effects array but where not there in the piece in prevPieces
    if (pieces && prevPieces && pieces.length === prevPieces.length) {
      const anyPieceGotSwamp = pieces.some((piece, index) => {
        const pieceHasSwamp1 = pieceHasEffect("swamp1");
        const pieceHasSwamp2 = pieceHasEffect("swamp2");
        const prevPiece = prevPieces[index];
        return (
          (pieceHasSwamp1(piece) && !pieceHasSwamp1(prevPiece)) ||
          (pieceHasSwamp2(piece) && !pieceHasSwamp2(prevPiece))
        );
      });
      if (anyPieceGotSwamp) {
        playSfxSwampSinking();
      }
    }
  }, [pieces, prevPieces, playSfxSwampSinking]);

  // Play move sound if piece moved if a piece collects an action card,
  // play the sound for collecting an action card
  useMoveSound(
    prevItems,
    pieces,
    prevPieces,
    playSfxMoving,
    playSfxAcRanger,
    playSfxAcRocketLady,
    playSfxAcSniper,
  );

  useCollectedItemSound(
    log,
    prevLog,
    playSfxGrenadeBlueRanger,
    playSfxGrenadeBlueRocketLady,
    playSfxGrenadeBlueSniper,
    playSfxGrenadeRedRanger,
    playSfxGrenadeRedRocketLady,
    playSfxGrenadeRedSniper,
    playSfxGrenadeGreenRanger,
    playSfxGrenadeGreenRocketLady,
    playSfxGrenadeGreenSniper,
    playSfxHealth,
    playSfxMedkitRanger,
    playSfxMedkitRocketLady,
    playSfxMedkitSniper,
    playSfxPotionRanger,
    playSfxPotionRocketLady,
    playSfxPotionSniper,
  );

  useEffect(() => {
    let muteNextActionCardSound = false;

    if (
      typeof gameState?.activeShooting?.target !== "undefined" &&
      gameState?.activeShooting?.target !==
        prevGameState?.activeShooting?.target
    ) {
      const targetPiece = gameState.pieces[gameState.activeShooting.target];
      if (targetPiece.controlledBy === playerIndex) {
        muteNextActionCardSound = true;
        switch (targetPiece.type) {
          case "ranger":
            playSfxDefendRanger();
            break;
          case "sniper":
            playSfxDefendSniper();
            break;
          case "rocket-lady":
            playSfxDefendRocketLady();
            break;
          default:
            break;
        }
      }
    }

    if (gameState?.log.length > prevGameState?.log.length) {
      // Event happened
      const countNewLogEntries =
        gameState?.log.length - prevGameState?.log.length;
      for (let i = 0; i < countNewLogEntries; i++) {
        const logEntry = gameState.log[gameState.log.length - 1 - i];
        if (logEntry.type === "stabbed") {
          if (logEntry.targetPieceType === "ranger") {
            playSfxStabRanger();
          } else if (logEntry.targetPieceType === "sniper") {
            playSfxStabSniper();
          } else {
            playSfxStabRocketLady();
          }
        } else if (
          logEntry.type === "action-card-played" &&
          !logEntry.card?.instantAction
        ) {
          if (logEntry.card?.type === "airstrike") {
            playSfxAcAirstrike();
          } else if (logEntry.card?.type === "roundhouse_kick") {
            playSfxRoundhouse();
          } else {
            if (!muteNextActionCardSound) {
              playSfxAc();
            }
          }
        } else if (
          logEntry.type === "itemConsumed" &&
          (logEntry.itemType === "medipack" ||
            logEntry.itemType === "medipack-small")
        ) {
          playSfxUseMed();
        } else if (logEntry.type === "grenade") {
          if (logEntry.suckedInByWormhole) {
            playSfxLandmineFail();
          } else {
            playSfxThrowGrenade();
          }
        } else if (logEntry.type === "turn-started") {
          if (gameState.turn === 0) {
            playSfxGameStart();
          } else if (logEntry.activePlayer === playerIndex) {
            playSfxYourTurn();
          }
        } else if (logEntry.type === "ready") {
          playSfxReady();
        } else if (
          logEntry.type === "landmine" &&
          logEntry.didExplode === true
        ) {
          playSfxLandmineExplosion();
        } else if (
          logEntry.type === "landmine" &&
          logEntry.didExplode === false
        ) {
          playSfxLandmineFail();
        } else if (logEntry.type === "shotHit") {
          const attackerPieceType = logEntry.attackerPieceType;
          const targetPieceType = logEntry.targetPieceType;
          if (logEntry.effects.indexOf("piercing") !== -1) {
            playSfxEraserGun();
          } else {
            if (
              attackerPieceType === "ranger" &&
              targetPieceType === "ranger"
            ) {
              playSfxRangerHitsRanger();
            }
            if (
              attackerPieceType === "ranger" &&
              targetPieceType === "rocket-lady"
            ) {
              playSfxRangerHitsRocketLady();
            }
            if (
              attackerPieceType === "ranger" &&
              targetPieceType === "sniper"
            ) {
              playSfxRangerHitsSniper();
            }
            if (
              attackerPieceType === "sniper" &&
              targetPieceType === "ranger"
            ) {
              playSfxSniperHitRanger();
            }
            if (
              attackerPieceType === "sniper" &&
              targetPieceType === "rocket-lady"
            ) {
              playSfxSniperHitRocketLady();
            }
            if (
              attackerPieceType === "sniper" &&
              targetPieceType === "sniper"
            ) {
              playSfxSniperHitSniper();
            }
            if (
              attackerPieceType === "rocket-lady" &&
              targetPieceType === "ranger"
            ) {
              playSfxRocketHitsRanger();
            }
            if (
              attackerPieceType === "rocket-lady" &&
              targetPieceType === "rocket-lady"
            ) {
              playSfxRocketHitsRocketLady();
            }
            if (
              attackerPieceType === "rocket-lady" &&
              targetPieceType === "sniper"
            ) {
              playSfxRocketHitsSniper();
            }
          }
        } else if (logEntry.type === "shotMissed") {
          if (logEntry.attackerPieceType === "ranger") {
            playSfxMissRanger();
          } else if (logEntry.attackerPieceType === "rocket-lady") {
            playSfxMissRocketLady();
          } else if (logEntry.attackerPieceType === "sniper") {
            playSfxMissSniper();
          } else {
            playSfxMiss();
          }
        } else if (logEntry.type === "game-over" && !isSpectatorMode) {
          if (playerIndex === gameState.winner) {
            playSfxWin();
          } else {
            playSfxLoser();
          }
        }
      }
    }
  }, [
    gameState,
    prevGameState,
    playSfxStabRanger,
    playSfxStabRocketLady,
    playSfxStabSniper,
    playSfxAc,
    playSfxAcAirstrike,
    playSfxThrowGrenade,
    playSfxReady,
    playSfxUseMed,
    playSfxLandmineExplosion,
    playSfxLandmineFail,
    playSfxRangerHitsRanger,
    playSfxRangerHitsRocketLady,
    playSfxRangerHitsSniper,
    playSfxRocketHitsRanger,
    playSfxRocketHitsRocketLady,
    playSfxRocketHitsSniper,
    playSfxSniperHitRanger,
    playSfxSniperHitRocketLady,
    playSfxSniperHitSniper,
    playSfxWin,
    playSfxLoser,
    playerIndex,
    playSfxYourTurn,
    playSfxEraserGun,
    playSfxMiss,
    playSfxRoundhouse,
    playSfxGameStart,
    playSfxDefendSniper,
    playSfxDefendRanger,
    playSfxDefendRocketLady,
    playSfxMissRanger,
    playSfxMissRocketLady,
    playSfxMissSniper,
    isSpectatorMode,
  ]);
}

function useCollectedItemSound(
  log,
  prevLog,
  playSfxGrenadeBlueRanger,
  playSfxGrenadeBlueRocketLady,
  playSfxGrenadeBlueSniper,
  playSfxGrenadeRedRanger,
  playSfxGrenadeRedRocketLady,
  playSfxGrenadeRedSniper,
  playSfxGrenadeGreenRanger,
  playSfxGrenadeGreenRocketLady,
  playSfxGrenadeGreenSniper,
  playSfxHealth,
  playSfxMedkitRanger,
  playSfxMedkitRocketLady,
  playSfxMedkitSniper,
  playSfxPotionRanger,
  playSfxPotionRocketLady,
  playSfxPotionSniper,
) {
  useEffect(() => {
    if (log && prevLog && log.length > prevLog.length) {
      const newLogEntries = log
        .slice(prevLog.length)
        .filter((logEntry) => logEntry.type === "itemCollected");
      newLogEntries.forEach((logEntry) => {
        if (logEntry.itemType === "stun-grenade") {
          if (logEntry.pieceType === "ranger") {
            playSfxGrenadeBlueRanger();
          } else if (logEntry.pieceType === "rocket-lady") {
            playSfxGrenadeBlueRocketLady();
          } else if (logEntry.pieceType === "sniper") {
            playSfxGrenadeBlueSniper();
          }
        } else if (logEntry.itemType === "super-grenade") {
          if (logEntry.pieceType === "ranger") {
            playSfxGrenadeRedRanger();
          } else if (logEntry.pieceType === "rocket-lady") {
            playSfxGrenadeRedRocketLady();
          } else if (logEntry.pieceType === "sniper") {
            playSfxGrenadeRedSniper();
          }
        } else if (logEntry.itemType === "grenade") {
          if (logEntry.pieceType === "ranger") {
            playSfxGrenadeGreenRanger();
          } else if (logEntry.pieceType === "rocket-lady") {
            playSfxGrenadeGreenRocketLady();
          } else if (logEntry.pieceType === "sniper") {
            playSfxGrenadeGreenSniper();
          }
        } else if (logEntry.itemType === "medipack") {
          if (logEntry.pieceType === "ranger") {
            playSfxMedkitRanger();
          } else if (logEntry.pieceType === "rocket-lady") {
            playSfxMedkitRocketLady();
          } else if (logEntry.pieceType === "sniper") {
            playSfxMedkitSniper();
          }
        } else if (logEntry.itemType === "medipack-small") {
          if (logEntry.pieceType === "ranger") {
            playSfxPotionRanger();
          } else if (logEntry.pieceType === "rocket-lady") {
            playSfxPotionRocketLady();
          } else if (logEntry.pieceType === "sniper") {
            playSfxPotionSniper();
          }
        }
      });
    }
  }, [
    log,
    prevLog,
    playSfxGrenadeBlueRanger,
    playSfxGrenadeBlueRocketLady,
    playSfxGrenadeBlueSniper,
    playSfxGrenadeRedRanger,
    playSfxGrenadeRedRocketLady,
    playSfxGrenadeRedSniper,
    playSfxGrenadeGreenRanger,
    playSfxGrenadeGreenRocketLady,
    playSfxGrenadeGreenSniper,
    playSfxHealth,
    playSfxMedkitRanger,
    playSfxMedkitRocketLady,
    playSfxMedkitSniper,
    playSfxPotionRanger,
    playSfxPotionRocketLady,
    playSfxPotionSniper,
  ]);
}

function useMoveSound(
  prevItems,
  pieces,
  prevPieces,
  playSfxMoving,
  playSfxAcRanger,
  playSfxAcRocketLady,
  playSfxAcSniper,
) {
  useEffect(() => {
    if (pieces && prevPieces && pieces.length === prevPieces.length) {
      const anyPieceMoved = pieces.some((piece, index) => {
        const prevPiece = prevPieces[index];
        return piece.x !== prevPiece.x || piece.y !== prevPiece.y;
      });
      const pieceThatMovedToFieldWithItem = pieces.find((piece, index) => {
        const prevPiece = prevPieces[index];
        if (piece.x !== prevPiece.x || piece.y !== prevPiece.y) {
          return prevItems.some(
            (item) => item.x === piece.x && item.y === piece.y,
          );
        }
        return false;
      });
      if (pieceThatMovedToFieldWithItem !== undefined) {
        const pieceType = pieceThatMovedToFieldWithItem.type;
        const item = prevItems.find(
          (item) =>
            item.x === pieceThatMovedToFieldWithItem.x &&
            item.y === pieceThatMovedToFieldWithItem.y,
        );
        const itemType = item.type;
        if (
          itemType === "action-card-tier-1" ||
          itemType === "action-card-tier-2" ||
          itemType === "action-card-tier-3"
        ) {
          if (pieceType === "ranger") {
            playSfxAcRanger();
          } else if (pieceType === "rocket-lady") {
            playSfxAcRocketLady();
          } else if (pieceType === "sniper") {
            playSfxAcSniper();
          }
        }
      } else if (anyPieceMoved) {
        playSfxMoving();
      }
    }
  }, [
    prevItems,
    pieces,
    prevPieces,
    playSfxMoving,
    playSfxAcRanger,
    playSfxAcRocketLady,
    playSfxAcSniper,
  ]);
}
