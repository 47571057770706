import React from "react";

import { concatClassNames as cn } from "@sys42/utils";

import styles from "./styles.module.css";
import { capitalize } from "../../helpers";

import imgSuperGrenade from "./images/item-grenade-red.png";
import imgStunGrenade from "./images/item-grenade-blue.png";
import imgGrenade from "./images/item-grenade-green.png";
import imgMedipack from "./images/item-medkit.png";
import imgMedipackSmall from "./images/item-potion.png";

function ItemImage({ itemType }) {
  switch (itemType) {
    case "stun-grenade":
      return <img src={imgStunGrenade} alt="Stun Grenade" />;
    case "super-grenade":
      return <img src={imgSuperGrenade} alt="Super Grenade" />;
    case "grenade":
      return <img src={imgGrenade} alt="Grenade" />;
    case "medipack-small":
      return <img src={imgMedipackSmall} alt="Medipack Small" />;
    case "medipack":
      return <img src={imgMedipack} alt="Medipack" />;
    default:
      return <div>{itemType}</div>;
  }
}

export function PlayerPiece(props) {
  const {
    type,
    pos,
    playerIndex,
    playerName,
    isActiveShooter,
    isActiveTarget,
    isHoverState,
    isHighlighted,
    isControlledByActivePlayer,
    isInRange,
    effects,
    stackedItems,
    movementState,
    health,
    maxHealth,
    countActionPoints,
    onClick,
  } = props;

  const isSwamp1 = effects.includes("swamp1");
  const isSwamp2 = effects.includes("swamp2");

  const effectList = effects.join(", ");
  const effectListTitleString = effectList ? ` [${effectList}]` : "";

  function handleClick(e) {
    onClick(e);
  }

  return (
    <div
      onClick={handleClick}
      title={`${type} (${playerName})${effectListTitleString}`}
      className={cn(
        styles.playerPiece,
        styles[`playerPiece_type-${type}`],
        playerIndex === 0 && styles.playerPiece_player1,
        playerIndex === 1 && styles.playerPiece_player2,
        isActiveShooter && styles.playerPiece_activeShooter,
        isActiveTarget && styles.playerPiece_activeTarget,
        isHighlighted && styles.playerPiece_highlighted,
        isHoverState && styles.playerPiece_hoverState,
        isSwamp1 && styles.playerPiece_swamp1,
        isSwamp2 && styles.playerPiece_swamp2,
        isInRange && styles.playerPiece_inRange,
        isControlledByActivePlayer && styles.playerPiece_activePlayer,
        styles[`playerPiece_movementState${capitalize(movementState)}`],
        ...effects.map((effect) => styles[`playerPiece_effect-${effect}`]),
      )}
      style={{
        left: pos.x + "em",
        top: pos.y + "em",
      }}
    >
      {/*
    <div className={styles.pieceAp}>{piece.ap}</div>
    <div className={styles.pieceMp}>{piece.mp}</div>
    */}
      <div className={styles.stackedItems}>
        {stackedItems.map((stackedItem, index) => (
          <ItemImage key={index} itemType={stackedItem} />
        ))}
      </div>
      <div className={styles.health}>
        {[...Array(maxHealth)].map((x, i) => (
          <div
            key={i}
            className={cn(
              styles.healthSegment,
              health > i && styles.healthSegment_full,
            )}
          />
        ))}
      </div>
      <div className={styles.actionPoints}>
        {[...Array(countActionPoints)].map((x, i) => (
          <div key={i} className={styles.actionPointsSegment} />
        ))}
      </div>
    </div>
  );
}
