import React, { useEffect, useState } from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { useParams } from "react-router-dom";
import { useTroopersServer as useTroopers } from "../../hooks/useTroopersServer";
//import { useTroopersLocal as useTroopers } from "../../hooks/useTroopersLocal";
import {
  INTERFACE_MODE_DEFAULT,
  INTERFACE_MODE_SELECT_COORDINATES,
  INTERFACE_MODE_THROW_GRENADE,
  INTERFACE_MODE_THROW_SUPER_GRENADE,
  INTERFACE_MODE_THROW_STUN_GRENADE,
  INTERFACE_MODE_ACTION_MENU,
} from "../../constants";
import styles from "./styles.module.css";
import Board from "../../components/Board";
import { concatClassNames, usePrevious } from "../../helpers";
import { ActionCard } from "../../components/ActionCard";

import imgLose from "../../images/lose.webp";
import { useToopersSoundEffects } from "./useTroopersSoundEffects";
import { usePiecesInShootRange } from "../../hooks/usePiecesInShootRange";

function itemAtPosition(items, x, y) {
  return items.find((item) => item.x === x && item.y === y);
}

export default function Troopers() {
  // eslint-disable-next-line
  const { tableName } = useParams();
  const {
    gameState,
    sendAction,
    lastError,
    sendReady,
    playerIndex: thePlayerIndex,
  } = useTroopers(tableName);

  const [chooseCharacter, setChooseCharacter] = useState(null);
  const [interfaceMode, setInterfaceMode] = useState(INTERFACE_MODE_DEFAULT);
  const [actionToDispatchWithCoordinates, setActionToDispatchWithCoordinates] =
    useState(null);
  const [activePiece, setActivePiece] = useState(null);
  const [actionMenuTarget, setActionMenuTarget] = useState(null);
  const [username, setUsername] = useState("");
  const [localTime, setLocalTime] = useState(0);
  const turnDurationInSeconds = Math.max(
    0,
    Math.floor(
      (localTime -
        (gameState ? Date.parse(gameState.turnStartedTime) || 0 : 0)) /
        1000,
    ),
  );
  const turnDurationMinutes = Math.floor(turnDurationInSeconds / 60);
  const turnDurationSeconds = turnDurationInSeconds % 60;

  const turnDuration = `${
    turnDurationMinutes > 0 ? turnDurationMinutes + "m " : ""
  }${turnDurationSeconds}s`;
  // const [showLegacyControls, setShowLegacyControls] = useState(false);

  const thePlayer = gameState?.players[thePlayerIndex] ?? null;
  const isSpectatorMode = thePlayer === null;
  const hudPlayerIndex = isSpectatorMode
    ? gameState?.activePlayer
    : thePlayerIndex;
  const hudPlayer = gameState?.players[hudPlayerIndex] ?? null;

  const activePlayerIndex = gameState?.activePlayer ?? null;
  const prevActivePlayerIndex = usePrevious(activePlayerIndex);

  const piecesInShootRangeForHighlightedPiece = usePiecesInShootRange(
    activePiece,
    gameState,
  );

  useToopersSoundEffects(
    gameState,
    activePiece,
    thePlayerIndex,
    isSpectatorMode,
  );

  useEffect(() => {
    const interval = setInterval(() => setLocalTime(new Date()), 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (activePlayerIndex !== prevActivePlayerIndex) {
      setActivePiece(null);
    }
  }, [activePlayerIndex, prevActivePlayerIndex]);

  useEffect(
    function () {
      function handleKeyDown(e) {
        switch (e.key) {
          case "Escape":
            if (interfaceMode !== INTERFACE_MODE_DEFAULT) {
              e.preventDefault();
              setInterfaceMode(INTERFACE_MODE_DEFAULT);
            }
            break;
          case "ArrowUp":
            e.preventDefault();
            sendAction({ type: "move", dir: "N", pieceId: activePiece });
            break;
          case "w":
          case "W":
            sendAction({ type: "move", dir: "N", pieceId: activePiece });
            break;
          case "ArrowDown":
            e.preventDefault();
            sendAction({ type: "move", dir: "S", pieceId: activePiece });
            break;
          case "s":
          case "S":
            sendAction({ type: "move", dir: "S", pieceId: activePiece });
            break;
          case "ArrowRight":
            e.preventDefault();
            sendAction({ type: "move", dir: "E", pieceId: activePiece });
            break;
          case "d":
          case "D":
            sendAction({ type: "move", dir: "E", pieceId: activePiece });
            break;
          case "ArrowLeft":
            e.preventDefault();
            sendAction({ type: "move", dir: "W", pieceId: activePiece });
            break;
          case "a":
          case "A":
            sendAction({ type: "move", dir: "W", pieceId: activePiece });
            break;
          default:
            break;
        }
      }

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    },
    [sendAction, activePiece, interfaceMode],
  );

  // Automatically roll the action cards
  useEffect(() => {
    if (
      gameState?.distributeActionCards &&
      thePlayerIndex === gameState.activePlayer
    ) {
      sendAction({ type: "distributeActionCard" });
    }
  }, [gameState, sendAction, thePlayerIndex]);

  if (gameState === null) {
    return <div>Connecting…</div>;
  }

  /*  function handleClickShowLegacyControls() {
      setShowLegacyControls(!showLegacyControls);
    }*/

  function handleChangeUsername(e) {
    setUsername(e.target.value);
  }

  /*  function handleClickDistributeActionCard() {
      sendAction({ type: 'distributeActionCard' });
    }*/

  function handleClickStab() {
    setInterfaceMode(INTERFACE_MODE_SELECT_COORDINATES);
    setActionToDispatchWithCoordinates({ type: "stab", attacker: activePiece });
  }

  function handleClickShoot() {
    setInterfaceMode(INTERFACE_MODE_SELECT_COORDINATES);
    setActionToDispatchWithCoordinates({ type: "shoot", shooter: activePiece });
  }

  function handleClickActionMenuShoot(coordinates) {
    setInterfaceMode(INTERFACE_MODE_DEFAULT);
    sendAction({
      type: "shoot",
      shooter: activePiece,
      coordinates,
    });
  }

  function handleClickActionMenuStab(coordinates) {
    setInterfaceMode(INTERFACE_MODE_DEFAULT);
    sendAction({
      type: "stab",
      attacker: activePiece,
      coordinates,
    });
  }

  function handleClickActionMenuCancel() {
    setInterfaceMode(INTERFACE_MODE_DEFAULT);
  }

  function handleClickAttack() {
    sendAction({ type: "shootingAttack" });
  }

  function handleClickDefend() {
    sendAction({ type: "shootingDefend" });
  }

  function handleClickShootingAccept() {
    sendAction({ type: "shootingAccept" });
  }

  function handleClickConsumeItem() {
    sendAction({ type: "consumeItem", pieceId: activePiece });
  }

  function handleClickExchangeItem() {
    sendAction({ type: "exchangeItem", pieceId: activePiece });
  }

  function handleClickThrowGrenade() {
    setInterfaceMode(INTERFACE_MODE_THROW_GRENADE);
    setActionToDispatchWithCoordinates({
      type: "throwGrenade",
      shooter: activePiece,
    });
  }

  function handleClickThrowSuperGrenade() {
    setInterfaceMode(INTERFACE_MODE_THROW_SUPER_GRENADE);
    setActionToDispatchWithCoordinates({
      type: "throwGrenade",
      shooter: activePiece,
    });
  }

  function handleClickThrowStunGrenade() {
    setInterfaceMode(INTERFACE_MODE_THROW_STUN_GRENADE);
    setActionToDispatchWithCoordinates({
      type: "throwGrenade",
      shooter: activePiece,
    });
  }

  function handleClickPlayCard(card, cardIndex, playerIndex) {
    const playCardAction = { type: "playCard", cardIndex, playerIndex };
    if (
      [
        "piercing",
        "dumdum",
        "sniper_bullet",
        "laser_pointer",
        "super_special_scope",
        "scope",
      ].find((cardType) => cardType === card.type)
    ) {
      setInterfaceMode(INTERFACE_MODE_SELECT_COORDINATES);
      setActionToDispatchWithCoordinates({
        type: "shoot",
        cardIndex,
        shooter: activePiece,
      });
    } else if (["katana"].find((cardType) => cardType === card.type)) {
      setInterfaceMode(INTERFACE_MODE_SELECT_COORDINATES);
      setActionToDispatchWithCoordinates({
        type: "stab",
        cardIndex,
        attacker: activePiece,
      });
    } else if (
      ["beamer", "jetpack", "airstrike", "trampoline"].find(
        (cardType) => cardType === card.type,
      )
    ) {
      setInterfaceMode(INTERFACE_MODE_SELECT_COORDINATES);
      setActionToDispatchWithCoordinates({
        ...playCardAction,
        pieceId: activePiece,
      });
    } else if (
      [
        "pogostick",
        "swamp_boots",
        "super_swamp_boots",
        "seven_mile_boots",
        "one_mile_boots",
        "four_mile_boots",
        "two_mile_boots", // TODO get this from the action cards dynamically
        "energy_drink",
        "camouflage_vest",
        "roundhouse_kick",
      ].find((cardType) => cardType === card.type)
    ) {
      sendAction({ ...playCardAction, pieceId: activePiece });
    } else {
      sendAction(playCardAction);
    }
  }

  function handleClickStartingPosition(playerIndex, startingPositionIndex) {
    setChooseCharacter({ playerIndex, startingPositionIndex });
  }

  function handleClickBoardCell(meta) {
    if (
      [
        INTERFACE_MODE_SELECT_COORDINATES,
        INTERFACE_MODE_THROW_GRENADE,
        INTERFACE_MODE_THROW_STUN_GRENADE,
        INTERFACE_MODE_THROW_SUPER_GRENADE,
      ].indexOf(interfaceMode) !== -1
    ) {
      if (actionToDispatchWithCoordinates) {
        sendAction({
          ...actionToDispatchWithCoordinates,
          coordinates: meta.coordinates,
        });
      }
      setInterfaceMode(INTERFACE_MODE_DEFAULT);
    } else if (interfaceMode === INTERFACE_MODE_DEFAULT) {
      meta.path && moveAlongPath(meta.path);
    }
  }

  function moveAlongPath(path) {
    let currPos = {
      x: gameState.pieces[activePiece].x,
      y: gameState.pieces[activePiece].y,
    };
    function getDirByPos(posFrom, posTo) {
      if (posTo.x > posFrom.x) {
        return "E";
      }
      if (posTo.x < posFrom.x) {
        return "W";
      }
      if (posTo.y > posFrom.y) {
        return "S";
      }
      if (posTo.y < posFrom.y) {
        return "N";
      }
      return false;
    }
    for (const pathItem of path) {
      // different coordinate system
      const nextPos = { x: pathItem.y, y: pathItem.x };

      const dir = getDirByPos(currPos, nextPos);
      if (dir) {
        sendAction({ type: "move", dir, pieceId: activePiece });
      }
      currPos = nextPos;
    }
  }

  function handleClickPiece(pieceIndex) {
    const piece = gameState.pieces[pieceIndex];
    if (
      thePlayerIndex === piece.controlledBy &&
      thePlayerIndex === gameState.activePlayer
    ) {
      setActivePiece(pieceIndex);
    } else if (
      thePlayerIndex === gameState.activePlayer &&
      piece.controlledBy !== thePlayerIndex &&
      piecesInShootRangeForHighlightedPiece.includes(piece)
    ) {
      setActionMenuTarget(pieceIndex);
      setInterfaceMode(INTERFACE_MODE_ACTION_MENU);
    }
  }

  function handleClickChooseCharacter(type) {
    sendAction({
      type: "selectPieceType",
      playerIndex: chooseCharacter.playerIndex,
      startingPositionIndex: chooseCharacter.startingPositionIndex,
      pieceType: type,
    });
    setChooseCharacter(null);
  }

  return (
    <div className={styles.game}>
      {chooseCharacter && (
        <ChooseCharacter onChooseCharacter={handleClickChooseCharacter} />
      )}

      <WinnerOverlay
        winner={gameState.winner}
        players={gameState.players}
        thePlayerIndex={thePlayerIndex}
        isSpectatorMode={isSpectatorMode}
      />

      <div className={styles.boardAndSidebar}>
        <div>
          <Board
            interfaceMode={interfaceMode}
            gameState={gameState}
            onClickStartingPosition={handleClickStartingPosition}
            onClickCell={handleClickBoardCell}
            onClickPiece={handleClickPiece}
            actionMenuTarget={actionMenuTarget}
            piecesInShootRangeForHighlightedPiece={
              piecesInShootRangeForHighlightedPiece
            }
            onClickActionMenuShoot={handleClickActionMenuShoot}
            onClickActionMenuStab={handleClickActionMenuStab}
            onClickActionMenuCancel={handleClickActionMenuCancel}
            highlightedPiece={activePiece}
            onClickAttack={handleClickAttack}
            onClickDefend={handleClickDefend}
          />
          <div className={styles.belowBoard}>
            <div className={styles.statusBar}>
              <div>
                {gameState.players.map((player, playerIndex) => (
                  <div className={styles.playedCards}>
                    Played cards player {playerIndex + 1} ({player.hand.length}{" "}
                    in hand):
                    <div className={styles.playedCardsCards}>
                      {player.playedCards.map((playedCard, playedCardIndex) => (
                        <div
                          key={playedCardIndex}
                          className={styles.playedCardWrapper}
                        >
                          <ActionCard
                            className={styles.playedCard}
                            card={playedCard}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              {gameState.errors.map((error, index) => (
                <div key={index} className={styles.gameError}>
                  {error}
                </div>
              ))}
              {lastError?.message && (
                <div className={styles.serverError}>
                  Last server response: {lastError?.message}
                </div>
              )}
            </div>

            {gameState.players.map(
              (player, playerIndex) =>
                (playerIndex === thePlayerIndex || gameState.isDevMode) && (
                  <div className={styles.hand}>
                    {player.hand.map((card, cardIndex) => (
                      <ActionCard
                        className={styles.card}
                        key={cardIndex}
                        card={card}
                        onClickPlayCard={() =>
                          handleClickPlayCard(card, cardIndex, playerIndex)
                        }
                      />
                    ))}
                  </div>
                ),
            )}

            <div>
              {[...gameState.log].reverse().map((logItem) => (
                <div>{logItem.text}</div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.sidebar}>
          {gameState.turn === -1 && gameState.players.length === 2 && (
            <button onClick={() => sendAction({ type: "start" })}>
              Start game
            </button>
          )}

          {gameState.turn === -1 &&
            gameState.players.length < 2 &&
            thePlayerIndex !== null && <div>Waiting for other player…</div>}

          {thePlayerIndex === null && (
            <>
              <div>
                <input
                  type="text"
                  value={username}
                  onChange={handleChangeUsername}
                />
              </div>
              <button onClick={() => sendReady(username)}>Ready</button>
            </>
          )}

          {thePlayer ? (
            <>
              <h3 className={styles.playerHudTitle}>
                {thePlayer.name} {thePlayerIndex === 0 ? "(green)" : "(red)"}
              </h3>
              {thePlayerIndex === gameState.activePlayer ? (
                <button
                  className={styles.endTurnButton}
                  onClick={() => sendAction({ type: "endTurn" })}
                >
                  End turn ({turnDuration})
                </button>
              ) : (
                <button className={styles.enemyTurnButton}>
                  Enemy turn ({turnDuration})
                </button>
              )}
            </>
          ) : (
            <h3 className={styles.playerHudTitle}>Spectator</h3>
          )}

          {/*
          This is now done automatically
          (gameState.distributeActionCards && thePlayerIndex === gameState.activePlayer) &&
          <div>
            Distribute {gameState.actionCardsToDistribute} cards:
            <button onClick={handleClickDistributeActionCard} type={'button'}>Roll dice</button>
          </div>
          */}

          {gameState.activeShooting && (
            <div>
              ActiveShooting: <br />
              Effects: {gameState.activeShooting.effects.join(",")} <br />
              Attack: {gameState.activeShooting.attackValue}
              {gameState.activeShooting.attackValue === null && (
                <button onClick={handleClickAttack}>Attack</button>
              )}
              <br />
              Defense: {gameState.activeShooting.defenseValue}
              {gameState.activeShooting.attackValue !== null && (
                <button onClick={handleClickDefend}>Defend</button>
              )}
              <br />
              <button
                onClick={handleClickShootingAccept}
                disabled={
                  gameState.activeShooting.attackValue === null ||
                  gameState.activeShooting.defenseValue === null
                }
              >
                Accept result
              </button>
            </div>
          )}

          {hudPlayer && (
            <>
              <div className={styles.activeEffects}>
                Active effects: {hudPlayer.effects.join(", ") || "none"}
              </div>
              {gameState.pieces.map(
                (piece, pieceIndex) =>
                  piece.controlledBy === hudPlayerIndex && (
                    <div
                      className={concatClassNames(
                        styles.pieceInfo,
                        activePiece === pieceIndex && styles.pieceInfo_active,
                      )}
                    >
                      <div
                        className={styles.pieceInfoTitle}
                        onClick={() => setActivePiece(pieceIndex)}
                      >
                        {piece.type}
                        {piece.health === 0 && "(dead)"}
                      </div>

                      <div className={styles.pieceInfoRow}>
                        <div className={styles.pieceInfoLabel}>
                          Movement points
                        </div>
                        {piece.mp} of {piece.stats.mp}
                      </div>
                      <div className={styles.pieceInfoRow}>
                        <div className={styles.pieceInfoLabel}>
                          Action points
                        </div>
                        {piece.ap} of {piece.stats.ap}
                      </div>
                      <div className={styles.pieceInfoRow}>
                        <div className={styles.pieceInfoLabel}>
                          Firing range
                        </div>
                        {piece.firingRange >= 100 ? "∞" : piece.firingRange}
                      </div>
                      <div className={styles.pieceInfoRow}>
                        <div className={styles.pieceInfoLabel}>
                          Weapon damage
                        </div>
                        {piece.weaponDamage}
                      </div>
                      <div className={styles.pieceInfoRow}>
                        <div className={styles.pieceInfoLabel}>
                          Melee damage
                        </div>
                        {piece.meleeDamage}
                      </div>

                      <div className={styles.health}>
                        {[...Array(piece.stats.health)].map((x, i) => (
                          <div
                            key={i}
                            style={{ width: `${100 / piece.stats.health}%` }}
                            className={cn(
                              styles.healthSegment,
                              piece.health > i && styles.healthSegment_full,
                            )}
                          />
                        ))}
                      </div>

                      {piece.effects.length > 0 && (
                        <div className={styles.pieceInfoRow}>
                          Active Effects: {piece.effects.join(", ")}
                        </div>
                      )}
                      {piece.stackedItems.map((item) => {
                        if (item === "grenade") {
                          return (
                            <button
                              disabled={activePiece !== pieceIndex}
                              className={styles.buttonAction}
                              onClick={handleClickThrowGrenade}
                            >
                              Throw {item}
                            </button>
                          );
                        } else if (item === "super-grenade") {
                          return (
                            <button
                              disabled={activePiece !== pieceIndex}
                              className={styles.buttonAction}
                              onClick={handleClickThrowSuperGrenade}
                            >
                              Throw {item}
                            </button>
                          );
                        } else if (item === "stun-grenade") {
                          return (
                            <button
                              disabled={activePiece !== pieceIndex}
                              className={styles.buttonAction}
                              onClick={handleClickThrowStunGrenade}
                            >
                              Throw {item}
                            </button>
                          );
                        } else {
                          return (
                            <button
                              disabled={activePiece !== pieceIndex}
                              className={styles.buttonAction}
                              onClick={handleClickConsumeItem}
                            >
                              Use {item}
                            </button>
                          );
                        }
                      })}
                      {piece.stackedItems.length > 0 &&
                        itemAtPosition(gameState.items, piece.x, piece.y) && (
                          <button
                            disabled={activePiece !== pieceIndex}
                            className={styles.buttonAction}
                            onClick={handleClickExchangeItem}
                          >
                            Exchange item
                          </button>
                        )}
                      <button
                        disabled={activePiece !== pieceIndex}
                        className={styles.buttonAction}
                        onClick={handleClickStab}
                      >
                        {piece.type === "medic" ? "Stab/Heal" : "Stab"}
                      </button>
                      <button
                        disabled={activePiece !== pieceIndex}
                        className={styles.buttonAction}
                        onClick={handleClickShoot}
                      >
                        Shoot
                      </button>
                    </div>
                  ),
              )}
            </>
          )}

          {gameState.isDevMode && (
            <div>
              <button
                onClick={() =>
                  sendAction({ type: "loadTestState", name: "stunGrenadeTest" })
                }
              >
                Load stunGrenadeTest
              </button>
              <br />
              <button
                onClick={() =>
                  sendAction({ type: "loadTestState", name: "defaultDevGame" })
                }
              >
                Load defaultDevGame
              </button>
              <br />
              <button
                onClick={() =>
                  sendAction({ type: "loadTestState", name: "winWithOneShot" })
                }
              >
                Load winWithOneShot
              </button>
              <br />
              <button
                onClick={() =>
                  sendAction({ type: "loadTestState", name: "landmineTest" })
                }
              >
                Load landmineTest
              </button>
              <br />
              <button
                onClick={() =>
                  sendAction({ type: "loadTestState", name: "bulletTest" })
                }
              >
                Load bulletTest
              </button>
              <br />
              <button onClick={() => console.log(gameState)}>
                Log gameState
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ChooseCharacter({ onChooseCharacter }) {
  return (
    <div className={styles.overlayContainer}>
      <div className={styles.selectCharacterOverlay}>
        <button onClick={() => onChooseCharacter("ranger")}>Ranger</button>
        <button onClick={() => onChooseCharacter("sniper")}>Sniper</button>
        <button onClick={() => onChooseCharacter("rocket-lady")}>
          Rocket Lady
        </button>
        <button onClick={() => onChooseCharacter("rambo-girl")}>
          Rambo Girl
        </button>
        <button onClick={() => onChooseCharacter("medic")}>Medic</button>
      </div>
    </div>
  );
}

function WinnerOverlay({ winner, players, thePlayerIndex, isSpectatorMode }) {
  if (winner === null) {
    return null;
  }

  if (isSpectatorMode) {
    return (
      <div className={styles.overlayContainer}>
        <div className={styles.winOverlay}>
          {winner === -1 ? <>Draw</> : <>{players[winner].name} won!</>}
        </div>
      </div>
    );
  } else if (winner === -1) {
    return (
      <div className={styles.overlayContainer}>
        <div className={styles.winOverlay}>Draw</div>
      </div>
    );
  } else if (winner === thePlayerIndex) {
    return (
      <div className={styles.overlayContainer}>
        <div className={styles.winOverlay}>You won</div>
      </div>
    );
  } else {
    return (
      <div className={styles.overlayContainer}>
        <div className={styles.looseOverlay}>
          You lost
          <img src={imgLose} alt={"You lost"} />
        </div>
      </div>
    );
  }
}
