import { useMemo } from "react";
import { isBulletObstacle, isPosInRange } from "../wargame/helpers";

export const usePiecesInShootRange = function (
  shooterPieceIndex,
  gameState,
  fromPos = null,
) {
  const piecesInShootRange = useMemo(() => {
    if (
      shooterPieceIndex === null ||
      typeof gameState?.pieces === "undefined"
    ) {
      return [];
    }
    const shooterPiece = gameState.pieces[shooterPieceIndex];
    let shooterPosition;
    if (fromPos === null) {
      shooterPosition = { x: shooterPiece.x, y: shooterPiece.y };
    } else {
      shooterPosition = fromPos;
    }
    return gameState.pieces.filter((piece) => {
      const isObstacle = isBulletObstacle(
        gameState.board.layout,
        gameState.pieces,
        [],
        shooterPiece,
      );
      const [inRange, obstacleFree] = isPosInRange(
        piece.x,
        piece.y,
        shooterPosition.x,
        shooterPosition.y,
        shooterPiece.firingRange,
        isObstacle,
      );
      return (
        inRange &&
        obstacleFree &&
        piece.controlledBy !== shooterPiece.controlledBy
      );
    });
  }, [shooterPieceIndex, gameState?.pieces, gameState?.board.layout, fromPos]);

  return piecesInShootRange;
};
