import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

import Lobby from "./containers/Lobby";
import Troopers from "./containers/Troopers";

function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/troopers/:tableName">
            <Troopers />
          </Route>
          <Route path="/">
            <Lobby />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
